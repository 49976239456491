/* Wallet Checker container styling */
.wallet-checker-container {
    background-color: #1e1e1e;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 200px;
    text-align: center;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #dee2e6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Header styling */
.wallet-checker-container h2 {
    font-size: 24px;
    color: #ff8c00;
    margin-bottom: 20px;
}

/* Connected wallet address styling */
.wallet-address {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 16px;
    letter-spacing: 2px;
    word-spacing: 8px; /* Extra spacing between the 4-character groups */
}

/* Button styling */
.check-button, .connect-button {
    background-color: #ff8c00;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    width: 100%;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.check-button:hover, .connect-button:hover {
    background-color: #ffcc00;
}

/* Text for loading */
.loading-text {
    color: #ffcc00;
    font-weight: bold;
    margin-top: 20px;
}

/* Error message styling */
.error-text {
    color: red;
    font-weight: bold;
    margin-top: 20px;
}

/* Eligible message styling */
.eligible-text {
    color: green;
    font-weight: bold;
    margin-top: 20px;
}

/* Not eligible message styling */
.not-eligible-text {
    color: red;
    font-weight: bold;
    margin-top: 20px;
}

/* Connect message styling */
.connect-message {
    color: #ffcc00;
    margin-bottom: 20px;
}
