/* Ana kapsayıcı */
.hero-container {
  width: 100%;
  height: 100vh;
  overflow: hidden; /* Sayfa dışına taşanları gizle */
  position: relative;
}

/* Sayfalar kapsayıcı */
.pages-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300vh; /* 3 sayfa için 300vh */
}

/* Her sayfa tam ekran boyutunda olmalı */
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.8s ease-in-out;
  color: white;
}

/* Sayfa arka plan renkleri */
.page1 {
  background-image: url("../assets/page1bg.png");
  background-size: cover; /* Resmi tüm sayfaya uyarlar */
  background-position: center top 40%; /* Resmi ortalar */
}


.page2 {
  background-image: url("../assets/page2.jpeg");
  background-size: cover; /* Resmi tüm sayfaya uyarlar */
  background-position: center; /* Resmi ortalar */
}

.page3 {
  background-image: url("../assets/page3.jpeg");
  background-size: cover; /* Resmi tüm sayfaya uyarlar */
  background-position: center; /* Resmi ortalar */
}

.table-container {
  position: absolute; /* Bu pozisyonlandırmayı yaparak tam ortada tutarız */
  top: 14%;
  left: 49%;
  transform: translate(-50%, -50%);
  width: 55%; /* Tabelanın boyutuna göre ayarla */
  height: auto;
}

.gif-content {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; /* GIF'in dışa taşmaması için */
}

/* FAQ Bölümü */
.faq-container {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.faq-item {
  margin-bottom: 10px;
}

.faq-button {
  width: 100%;
  background-color: #ff8c00;
  color: white;
  padding: 15px;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.faq-button:hover {
  background-color: #ff5f00;
}

.faq-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 0 15px;
  border-radius: 5px;
}

.faq-content.open {
  max-height: 300px;
  padding: 15px;
}

.faq-icon {
  float: right;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.faq-icon.open {
  transform: rotate(180deg);
}


/* Nokta kapsayıcı */
.scroll-dots {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Noktalar */
.dot {
  width: 12px;
  height: 12px;
  background-color: gray;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.dot.active {
  width: 16px;
  height: 16px;
  background-color: white;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.8);
}

/* İçerik için stil */
.content {
  text-align: center;
  font-size: 1.5em;
  padding: 20px;
}
