/* src/App.css */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; 
  margin: 0;
  font-family: 'Arial', sans-serif;
}

main {
  flex-grow: 1;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
  position: relative;
  height: 100vh;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: relative;
  z-index: 1;
}

.mint-section {
  margin-top: 200px;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;
}

.mint-button {
  background-color: #fdd835;
  color: black;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.mint-button:hover {
  background-color: #ffeb3b;
}


/* Temel stil */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
  overflow-x: hidden;
}

/* Tiger Images */
.scaled-tiger {
display: inline-block;
margin: auto;
  width: 150px;
  height: auto;
  transition: transform 0.3s ease;
}

/* Fix the layout for the paragraph */
.text-darkforeground {
  color: #fff; /* Makes the text transparent to show the background image */
  max-width: 800px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 100px;
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-bold {
  font-weight: bold;
  font-style: italic;
  color: #f9b360;
}

/* Adjust section padding and alignment */
#about {
  padding: 7rem 0;
  text-align: center;
}

.tiger-hovered {
  transform: scale(1.1);
}

.scaled-tiger:hover {
  transform: scale(1.1);
}

/* Genel arka plan ve renk ayarları */
.bg-primary {
  background-color: #ff6600;
}

.bg-secondarybg {
  background-color: #333;
}

.text-primary {
  color: #ff6600;
}

.text-secondary {
  color: #ffcc00;
}

.text-light {
  color: #fff;
}

/* Hero image ve başlık */
.hero-image {
  width: 100%;
  max-width: 700px;
  margin: 2rem auto;
  animation: fadeIn 1.5s ease-in-out;
  -webkit-text-stroke: 1px #111; /* Orange stroke for outline */
}

.main-title {
  font-size: 6vw;
  text-align: center;
  margin-top: 50px;
  animation: bounce 2s infinite alternate;

  /* Tiger texture */
  background-image: url('./assets/tiger-texture.png'); /* Replace with your tiger texture image */
  background-size: cover; /* Ensures the image covers the text area */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Makes the text transparent to show the background image */

}



/* Görsel geçiş animasyonu */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

/* Zıplama animasyonu */
@keyframes bounce {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-15px);
  }
  100% {
      transform: translateY(0);
  }
}

/* Yeni animasyonlar */
@keyframes slideInLeft {
  0% {
      transform: translateX(-100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes slideInRight {
  0% {
      transform: translateX(100%);
      opacity: 0;
  }
  100% {
      transform: translateX(0);
      opacity: 1;
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
  }
}

/* Bölüm başlığı ve animasyonları */
.section-header {
  text-align: center;
  font-size: 3rem;
  color: #ffcc00;
  animation: slideInLeft 1.5s ease-in-out;
}

/* Vision bölüm stili */
.vision-section {
  background-color: #111;
  padding: 4rem 2rem;
}

.vision-content {
  text-align: center;
  font-size: 1.5rem;
  color: #ffcc00;
  animation: slideInRight 1.5s ease-in-out;
}

/* Sıkça Sorulan Sorular (FAQ) bölüm stilleri */
#faq {
  background-color: #222;
  padding: 4rem 0;
}

#faq h1 {
  color: #ff6600;
  font-size: 4rem;
  text-align: center;
  margin-bottom: 2rem;
  animation: pulse 2s infinite ease-in-out;
}

details {
  background-color: #333;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

details summary {
  font-size: 1.5rem;
  color: #ffcc00;
  cursor: pointer;
}

details summary:hover {
  color: #ff6600;
}

details[open] {
  background-color: #444;
}

/* Footer stilleri */
footer {
  position: fixed;
  margin-top: auto;
  background-color: #111;
  padding: 2rem;
  text-align: center;
  font-size: 0.9rem;
  color: #fff;
  z-index: 1000;
}

footer a {
  color: #ff6600;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #ffcc00;
}

/* Dalga şekli stili */
.wave-headline svg,
.wave-faq svg {
  fill: #ff6600;
}

/* Geçişli buton stilleri */
button, .btn {
  background-color: #ff6600;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover, .btn:hover {
  background-color: #ffcc00;
  transform: scale(1.05);
}

/* Geçiş yapan görseller */
.img-hover-zoom img {
  transition: transform 0.5s ease;
}

.img-hover-zoom:hover img {
  transform: scale(1.2);
}

/* Kart efekti */
.card {
  background-color: #333;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
}

/* Animasyonlar */
@keyframes glow {
  0% {
      box-shadow: 0 0 5px #ff6600;
  }
  50% {
      box-shadow: 0 0 20px #ff6600;
  }
  100% {
      box-shadow: 0 0 5px #ff6600;
  }
}

.glowing-border {
  border: 2px solid #ff6600;
  animation: glow 2s infinite ease-in-out;
}

