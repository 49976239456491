/* Launchpad ana konteyner */
.launchpad-container {
  position: relative;
  width: 100%;
  height: 90vh; /* Tüm ekranı kaplar */
  overflow: hidden; /* Taşmaları gizler */
}

/* Video arka plan */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* İçeriğin altında kalması için */
  overflow: hidden;
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Video içeriğini tam ekran yapar ve taşmaları kırpar */
  opacity: 0.7; /* Videoya hafif bir transparanlık ekleyerek içerik okunabilirliği artırılır */
}

/* İçerik overlay */
.content-overlay {
  position: relative;
  z-index: 1; /* İçeriği videonun üstüne getirir */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
}

/* Yazı animasyonu ve stili */
.animated-text {
  font-size: 6rem; /* Büyük yazı */
  font-weight: bold;
  background-size: cover;
  background-position: center;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Text arka planını gösterir */
  text-align: center;
  margin: 0;
  animation: bounce 2s infinite;
}

/* Bounce animation */
@keyframes bounce {
  0% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
  100% {
      transform: translateY(0);
  }
}
