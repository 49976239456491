.wallet-info-container {
  background: linear-gradient(90deg, #ff9966, #ff5e62);
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 1500px;
  height : 600px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 100px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

/* Pagination button styling */
.ant-pagination {
  display: flex;
  justify-content: center; /* Pagination butonlarını ortalar */
  margin-top: 20px;
}

.ant-pagination-item-link {
  color: #fff !important;
  border-radius: 5px !important; /* Butonların köşelerini yuvarlak yap */
  margin: 0 5px; /* Butonlar arasında boşluk bırak */
  display: flex;
  justify-content: center;
  align-items: center; /* Yazıyı ve simgeleri ortalar */
}

.ant-pagination-item a {
  color: #fff !important; /* Yazı rengini beyaz yap */
  font-weight: bold !important;
}

.ant-pagination-item-active {
  background-color: #ff8c00 !important; /* Aktif sayfa için turuncu renk */
  border-color: #ff8c00 !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}


.anticon {
  font-size: 16px; /* İkon boyutunu ayarlar */
  display: flex;
  justify-content: center;
  align-items: center; /* İkonu ortalar */
}



.token-tables {
  display: flex;
  justify-content: space-between; /* Tabloları yan yana koyar */
  gap: 20px; /* Tablolar arasında boşluk */
}

.token-table {
  width: 48%; /* Her tablo için genişlik */
}


h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  color: #fff; /* Başlıklar beyaz */
}

h2, h3 {
  color: #fff;
}

.error-message {
  color: #ff0000;
  margin-top: 10px;
}


.wallet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.wallet-icon {
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.wallet-icon:hover {
  color: #ffcc00; /* Hover ile parlak turuncu */
}

.wallet-address {
  font-weight: bold;
  color: #fff; /* Beyaz cüzdan adresi */
}

.wallet-balance, .token-item {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.balance-text, .token-name, .token-amount {
  font-size: 16px;
  font-weight: 500;
  color: #fff; /* Beyaz yazı */
}

.token-section-title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fff; /* Beyaz */
}

.token-icon {
  margin-right: 8px;
}

.token-list {
  background: #262626; /* Koyu gri arka plan */
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Yumuşak gölge */
}

.disconnect-button {
  margin-top: 20px;
  cursor: pointer;
  background: rgb(255, 255, 255);
  color: rgb(255, 94, 98);
  padding: 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.4s ease; /* Daha yumuşak geçiş */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Gölge */
}

.disconnect-button:hover {
  background-color: #fff; /* Hover ile renk değişimi */
  transform: scale(1.02); /* Hover ile büyüme efekti */
}

.disconnect-icon {
  margin-right: 8px;
}

.connect-wallet {
  padding: 12px;
  background: rgb(255, 255, 255);
  color: rgb(255, 94, 98);
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  display: inline-block;
  transition: background-color 0.4s ease, transform 0.4s ease; /* Daha yumuşak geçiş */
  box-shadow: 0 4px 12px rgba(255, 140, 0, 0.4); /* Turuncu parlama */
}

.connect-wallet:hover {
  background-color: #ffcc00; /* Hover ile parlak turuncu */
  transform: scale(1.1); /* Hafif büyüme efekti */
}

/* Search Bar Styling */

.input-container input {
  padding: 12px 20px;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
  border: 3px solid #1e1e1e;
  font-size: 16px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  text-align: center; /* Yazıyı yatay olarak ortaya alır */
  line-height: normal; /* Dikey olarak ortalar */
}

/* Focused input */
.input-container input:focus {
  outline: none;
  border-color: #ff8c00;
  box-shadow: 0 0 10px rgba(255, 140, 0, 0.3); /* Turuncu parlama efekti */
  background-color: #f9f9f9; /* Daha açık arka plan */
}



/* Table container styling */
.ant-table {
  background-color: #e1e1e1 !important; /* Koyu arka plan */
  border-radius: 10px !important;
  overflow: hidden !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5) !important; /* Derin gölge */
  border: 1px solid #ff8c00 !important; /* Turuncu sınır */
}

/* Table header styling */
.ant-table-thead > tr > th {
  background: rgb(27, 27, 27) !important; /* Gradient başlık arka planı */
  color: #fff !important; /* Beyaz yazı */
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: center !important;
  padding: 16px !important;
  letter-spacing: 0.5px !important;
  border-bottom: 2px solid #ffcc00 !important; /* Alt çizgi */
}

/* Table body styling */
.ant-table-tbody > tr > td {
  font-family: 'Arial', sans-serif !important;
  font-size: 20px !important;
  color: #ff8c00 !important; /* Turuncu yazı rengi */
  padding: 14px !important;
  text-align: center !important;
  border-bottom: 1px solid #2e2e2e !important; /* Satır ayırıcı */
}

/* Table row hover effect */
.ant-table-tbody > tr:hover {
  background-color: #ff8c00 !important; /* Hover ile satır arka planı turuncu */
  color: #ffffff !important; /* Yazılar beyaz */
  transition: background-color 0.4s ease, color 0.4s ease; /* Daha yumuşak geçiş */
}

/* Pagination styling */
.ant-pagination-item {
  background-color: #1b1b1b !important;
  border: 1px solid #ff8c00 !important;
}

.ant-pagination-item-active {
  background-color: #ff8c00 !important;
  border-color: #ffcc00 !important;
}

.ant-pagination-item-active a {
  color: #ffffff !important;
}

.ant-pagination-item a {
  color: #ffcc00 !important;
  font-weight: bold !important;
}


