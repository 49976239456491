.dashboard-container {
  padding: 20px;
  background-color: #0e0e0e;
  color: #ffffff;
}

h1 {
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
}
h5 {
  color: #e1e1e1;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.02r;
}


.content {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Treemap ve tablo arasındaki boşluğu artırır */
  margin-top: 20px;
}

.treemap-section {
  flex: 2;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
}

.treemap-section:hover {
  transform: scale(1.02); /* Treemap üzerine gelindiğinde hafif bir büyüme sağlar */
}

.table-section {
  flex: 1;
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
}

.table-section:hover {
  transform: scale(1.02); /* Tablo üzerine gelindiğinde hafif bir büyüme sağlar */
}

.table-section h2 {
  color: #ffffff;
  text-align: center;
  margin-bottom: 20px;
}

.ant-table-thead > tr > th {
  background-color: #343a40;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.ant-table-tbody > tr > td {
  color: #ffffff;
  text-align: center;
  font-size: 14px;
}

.ant-pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.ant-pagination-item {
  background-color: #2c3e50;
  border: none;
}

.ant-pagination-item-active {
  background-color: #82ca9d;
  border: none;
  color: white;
}

.ant-pagination-item a {
  color: #ffffff;
}

.ant-pagination-item-active a {
  color: white;
}

.ant-pagination-prev, .ant-pagination-next {
  color: #ffffff;
}

a {
  color: #1da1f2;
}

a:hover {
  text-decoration: underline;
}
