/* Header style */
header {
  background-color: #0e0e0e; /* Dark gray for header */
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #333; /* Subtle border for separation */
}

/* Logo style */
.logo {
  width: 50px;
  height: auto;
  margin-right: 15px;
}

/* Main heading style */
h1 {
  margin: 0;
  font-size: 2.5em;
  letter-spacing: 0.05em;
  color: #ffffff; /* Bright white for header text */
  text-shadow: 0 0 6px rgba(255, 255, 255, 0.3); /* Subtle glow for elegance */
}

h1 .highlight {
  background-image: url('../assets/tiger-texture.png'); /* Replace with your tiger texture image */
  background-size: cover; /* Ensures the image covers the text area */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Makes the text itself transparent to show the background image */
  text-shadow: 0 0 10px #ff8c00, 0 0 20px #ff8c00; /* Neon glow effect */
}

/* Navbar style */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  background-size: contain;
  position: relative; /* Make nav items stack properly */
}

/* Flexbox adjustments for navbar */
nav .flex {
  flex-direction: row; /* Ensure items are arranged horizontally */
  align-items: center; /* Align logo and navbar links on the same line */
  justify-content: center; /* Center the links and social icons */
}

.flex .gap-4 {
  gap: 0; /* Reduces unnecessary spacing */
}

/* Logo inside navbar */
nav img {
  width: 100px;
  height: auto;
  margin: 0 20px; /* Add some margin between logo and buttons */
}

/* Navbar links */
nav a {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin: 0 10px; /* Add some margin between buttons */
  text-decoration: none; /* Remove underline */
  position: relative;
  padding: 10px 15px;
  border-radius: 5px;
}

/* Hover effect for navbar links */
nav a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff6600;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

nav a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

nav a:hover {
  transform: scale(1.1);
  color: #ffcc00;
}

/* Selected (active) link transparent orange background */
nav a.active {
  background-color: rgba(255, 140, 0, 0.2); /* Transparent orange */
  color: #ffcc00; /* Text color for active link */
  padding: 10px 15px;
  border-radius: 5px; /* Rounded corners for the selected item */
  position: relative; /* Ensures the background applies to the entire element */
}

/* Social links style */
nav .social-links {
  margin-left: 0px; /* Add some margin between links and social icons */
}

nav .social-links a {
  margin: 0 5px; /* Add some margin between social icons */
}

nav .social-links img {
  width: 15px; /* Reduce the size of the social icons */
  height: 15px;
}

.nav-item1 {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin: 0 10px; /* Add some margin between buttons */
  text-decoration: none; /* Remove underline */
  position: relative;
  padding: 10px 15px;
  border-radius: 5px; /* Rounded corners for active link */
  display: flex;
  align-items: center;
}


/* Navbar links */
.nav-item {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.3s ease;
  margin: 0 10px; /* Add some margin between buttons */
  text-decoration: none; /* Remove underline */
  position: relative;
  padding: 6px 9px;
  border-radius: 5px; /* Rounded corners for active link */
  display: flex;
  align-items: center;
}

/* Hover effect for navbar links */
.nav-item:hover {
  color: #ffcc00;
}

/* Active link with transparent orange background */
.nav-item.active {
  background-color: rgba(255, 140, 0, 0.2); /* Transparent orange */
  color: #ffcc00; /* Active text color */
  padding: 15px 20px;
  border-radius: 5px;
}

/* Hover effect underline animation */
.nav-item::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff6600;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.nav-item:hover::before {
  visibility: visible;
  transform: scaleX(1);
}

/* Divider line between menu items */
.divider {
  width: 1px;
  height: 40px;
  background-color: #ccc;
  margin: 0px 20px;
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    flex-direction: column;
    gap: 5px;
  }
}
