.footer {
  background-color: #1a1a1a; /* Dark background */
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-bottom: auto;
}

.footer-content {
  display: flex; /* Flexbox layout for horizontal alignment */
  justify-content: center; /* Center the buttons horizontally */
  align-items: center; /* Center the buttons vertically */
  margin-bottom: 10px;
  flex-wrap: wrap; /* Ensure that buttons wrap on smaller screens */
}

.footer-social {
  display: flex; /* Flexbox for the social icons container */
  justify-content: center;
  align-items: center;
  gap: 20px; /* Add space between the buttons */
}

.footer-social a {
  display: flex; /* Flexbox for centering logo inside the button */
  justify-content: center;
  align-items: center;
  width: 40px; /* Adjusted button width */
  height: 40px; /* Adjusted button height */
  background-color: #292929; /* Button background color */
  border-radius: 8px; /* Slight rounding for the button */
  transition: transform 0.4s ease, box-shadow 0.4s ease, border 0.2s ease;
  position: relative; /* For outline placement */
}

.footer-social img {
  width: 20px; /* Smaller logo inside the button */
  height: 20px;
  opacity: 0.85; /* Initial opacity */
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.footer-social a:hover {
  transform: scale(1.1); /* Slightly enlarge the button on hover */
  border: 2px solid rgba(255, 140, 0, 0.8); /* Outline around the button */
}

.footer-social img:hover {
  opacity: 1; /* Fully visible logo on hover */
}

.footer-bottom {
  border-top: 1px solid #333; /* Subtle top border */
  padding-top: 5px;
  font-size: 0.4em;
  color: #888888; /* Light gray for the copyright text */
}

.footer-bottom p {
  margin: 0;
}
