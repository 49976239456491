/* Slider container */
.slider-container {
    position: relative;
    width: 5000px; /* Görünür olan alan genişliği */
    height: 100%; /* Yükseklik */
    overflow: hidden; /* Ekranın dışına çıkanları gizle */
    margin: 400px auto 0;
}

/* Slider track: tüm NFT'leri taşıyan çizgi */
.slider-track {
    display: flex;
    align-items: center;
    transition: transform 0.05s linear; /* NFT'ler yumuşakça kayar */
}

/* Her bir NFT item */
.nft-item {
    min-width: 150px; /* Her NFT'nin genişliği */
    height: 150px;
    margin-right: 20px; /* NFT'ler arasında boşluk */
}

/* NFT item içindeki görüntü */
.nft-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
